import VersionBackModule from "@/store/modules/VersionBack-module";
import { UtilsString } from "@/utils/utils-string";

export default class VersionChecker {
    private static versionKey = "appVersion";

    public static async checkForUpdate() {
        try {
            await VersionBackModule.getVersionFront();
            const version = VersionBackModule.versionfront;
            const currentVersion = localStorage.getItem(this.versionKey);
            console.log("Comprovando versión. Versión actual:", currentVersion, "Versión nueva:", version);
            if (currentVersion && currentVersion !== version) {
                console.log("Nueva versión detectada. Recargando...");
                localStorage.setItem(this.versionKey, version);
                window.location.reload();
            } else {
                localStorage.setItem(this.versionKey, version);
            }
        } catch (error) {
            console.error("Error obteniendo la versión:", error);
        }
    }

    public static async Version() {
        if (UtilsString.IsNullOrWhiteSpace(VersionBackModule.versionfront)) {
            await VersionBackModule.getVersionFront();
        }
        return VersionBackModule.versionfront;
    }

    public static startVersionCheck(interval: number = 300000) {
        this.checkForUpdate(); // Primera verificación
        setInterval(this.checkForUpdate, interval);
    }
}
